<template>
  <div class="panel-container">
  flag
  </div>
</template>

<script>


export default {
  data() {
    return {
      
    };
  },
  components: {
    
  },
  created() {
 
  },
  methods: {
   
  },
};
</script>

<style lang="scss" scoped>
.panel-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
height: calc(100vh - 150px);
  overflow: hidden;
  border: 2px solid #ddd;
  border-radius: 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 30px #fff;
  margin: 20px;
  opacity: 1;
 
}
</style>